import React from 'react';
import Layout from '../components/layout/Layout';
import LabelText from '../components/LabelText';
import { Link } from 'gatsby';
import Header2 from '../components/layout/Header2';
import { useIntl } from 'gatsby-plugin-intl';
import { window } from 'browser-monads';

const ConditionsPage = () => {
  const language = window.location.pathname.substring(0, 3);
  const intl = useIntl();
  const titreMensions = intl.formatMessage({ id: 'mensions.titre' });
  const siege = intl.formatMessage({ id: 'mensions.siege' });
  const nom = intl.formatMessage({ id: 'mensions.nom' });
  const representant = intl.formatMessage({ id: 'mensions.representant' });
  const adresse = intl.formatMessage({ id: 'mensions.adresse' });
  const tel = intl.formatMessage({ id: 'mensions.tel' });
  const inscription1 = intl.formatMessage({ id: 'mensions.inscription-1' });
  const inscription2 = intl.formatMessage({ id: 'mensions.inscription-2' });
  const tva = intl.formatMessage({ id: 'mensions.tva' });
  const immat = intl.formatMessage({ id: 'mensions.immat' });
  const conceptionby = intl.formatMessage({ id: 'mensions.conceptionby' });
  const hostingby = intl.formatMessage({ id: 'mensions.hostingby' });
  const goBack = intl.formatMessage({ id: 'goback' });

  return (

    <Layout>
      <Header2 />
      <div className='mentions'>
        <LabelText className='mb-8 text-gray-600 text-3xl text-center'>{titreMensions}</LabelText>
        <div className='bloc'>
          <p className='text-lg'><b>{siege} : </b>805 Chemin du Pey de la Salle, 83440 Fayence, FRANCE</p>
          <p className='text-lg'><b>{nom} : </b>Art'n'Green</p>
          <p className='text-lg'><b>Siret : </b>49177433700027</p>
          <p className='text-lg'><b>{representant} : </b> DUPONT Jean-Emmanuel</p>
          <p className='text-lg'><b>{adresse} : </b> 805 Chemin du Pey de la Salle, 83440 Fayence, FRANCE</p>
          <p className='text-lg'><b>{tel} : </b> +33 784325610</p>
          <p className='text-lg'><b>Contact : </b> j.emmanuel.dupont@gmail.com</p>
          <p className='text-lg'><b>{inscription1}</b></p>
          <p className='text-lg'><b>{inscription2} : </b></p>
          <p className='text-lg'><b>{tva} : </b></p>
          <p className='text-lg'><b>{immat} : </b></p>
          <div className='gindev mt-28'>
            <p className='text-lg'>{conceptionby} gilles giannini</p>
            <p className='text-lg'>{hostingby} Netlify</p>
          </div>
        </div>
      </div>
      <div className='goBack'>
        <Link className='mb-8 text-gray-600 text-3xl text-center' to={language + '/'}>{goBack}</Link>
      </div>
    </Layout>
  );
};
export default ConditionsPage;
